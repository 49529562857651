body {
  background-color: #1f487c;
}
.bg-color {
  background-image: linear-gradient(1deg, #141727, #1f487c);
  width: 100%;
  height: 100vh;
  background: url(../assets/images/blob-scene-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.login-container {
  position: relative;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}
.logo {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  align-items: center;
}
.logo img {
  width: 130px;
}
.box-login {
  margin-bottom: 15px;
  padding: 30px 0;
  background-color: #04518a1a !important;
}
.formgroup {
  padding: 30px;
}
.formgroup > div {
  margin-bottom: 15px;
  border: 1px solid #001320;
  font-size: 14px;
}
.box-login h5 {
  font-size: 25px;
  color: #57b0c7;
}
.login-container button {
  background-image: linear-gradient(253deg, #3daaeb, #3b668c);
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  text-transform: capitalize;
}
.login-container button:hover {
  background-image: linear-gradient(253deg, #3daaeb, #3b668c);
  box-shadow: 0 14px 26px -12px rgba(2, 2, 48, 0.4),
    0 4px 23px 0 rgba(54, 3, 122, 0.15), 0 8px 10px -5px rgba(84, 30, 233, 0.2);
}
.login-container button span {
  font-size: 1.2rem;
}
.MuiInputBase-root {
}
.formgroup input {
  background: #fff;
  font-size: 1rem;
}

.login-container {
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #222 !important;
  caret-color: #222 !important;
  font-size: 1rem !important;
}
